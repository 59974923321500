import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/images',
    name: 'images',
    component: () => import('../views/ImagesView.vue')
  },
  {
    path: '/images/:year',
    name: 'folderImages',
    component: () => import('../views/FolderImagesView.vue')
  },
  {
    path: '/activities',
    name: 'ActivitiesView2',
    component: () => import ('../views/ActivitiesView2.vue')
  },
  {
    path: '/membership',
    name: 'MembershipView',
    component: () => import ('../views/MembershipView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
