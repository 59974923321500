<template>
  <NavComponent />
    <router-view/>
</template>

<script>
require('@/assets/mystyle.scss');

import NavComponent from '@/components/Nav.vue'

export default {
  components: {
    NavComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
