<template>
  <div class="home mt-6">
    <div class="columns is-centered" style="margin-top: .5em;">
      <div class="column">
        <h1 class="title">Covenant Outfitters</h1>
      </div>
    </div>
    <div class="columns is-flex is-centered is-vcentered">
      <div class="column is-one-third">
        <figure class="image is-square">
          <img class="is-centered" alt="Covenant Outfitters Logo" src="../assets/logo.png">
        </figure>
      </div>
      <div class="column is-one-third">
        <p class="title">We exist to outfit people for life and the great outdoors</p>
        <p class="subtitle">"The Lord confides in those who fear Him; He makes His covenant known to them" - Psalms 25:14</p>
      </div>
    </div>
    <div class="columns is-centered">
        <div class="column is-one-third">
          <p class="title">Purpose, Mission, & Values</p>
          <p class="subtitle">
            Help people know and love our Creator by stewarding God's creation in a way that creates opportunities to outfit people for the great outdoors and for life. We will work towards this aim with the following lenses: <b>sharing</b>, <b>generosity</b>, <b>hospitality</b>, <b>joy</b>, and <b>relationships</b>. 
            <br>Read more about our purpose, mission, and values <router-link to="/membership#Purpose">here</router-link>.
          </p>
        </div>
        <div class="column is-half" style="min-width: 530px;">
        <splide :options="options">
          <splide-slide>
              <figure class="image is4by3">
                <img src="/images/trail-cam/2022/25.webp">
              </figure>
          </splide-slide>
          <splide-slide>
            <figure>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/3LN-lZCpjmo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </figure>
          </splide-slide>
          <splide-slide>
            <figure class="image is4by3">
              <img src="/images/highlights/16.webp">
            </figure>
          </splide-slide>
          <splide-slide>
            <figure class="image is4by3">
              <img src="/images/highlights/10.webp">
            </figure>
          </splide-slide>

          <splide-slide>
            <figure class="image is4by3">
              <img src="/images/trail-cam/2022/11.webp">
            </figure>
          </splide-slide>
        </splide>
        </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-one-third">
        <p class="title">Learn or contact us today</p>
        <p class="subtitle">Send an email to CovenantOutfitters@gmail.com or see some of our <router-link to="/images/2022?prefixImgPath=trail-cam/2022">recent trail cam photos</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'HomeView',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
      return {
        options: {
          rewind : true,
          heightRatio  : .75,
          gap    : '1rem',
          autoplay: true
        },
      };
    },
}
</script>

<style>

.home > .columns > .column {
  text-align: center;
  vertical-align: center;
}
</style>