<template>
      <nav class="navbar p-2 is-fixed-top" role="navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <img alt="Covenant Outfitters Logo" src="@/assets/logo.png">
          Covenant Outfitters
      </router-link>
      <a role="button" class="navbar-burger" id="burger" aria-label="menu" 
        aria-expanded="false" data-target="navbarMenu" 
        @click="burgerHandler"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" id="navbarMenu">
      <div class="navbar-start">
        <router-link :to="`/images`" class="navbar-item" @click="closeBurgerHandler">
          <span class="icon"><font-awesome-icon icon="fa-solid fa-camera" /></span>
          <span>Images</span>
        </router-link>
        <router-link :to="`/activities`" class="navbar-item" @click="closeBurgerHandler">
          <span class="icon"><font-awesome-icon icon="fa-solid fa-campground" /></span>
          <span>Activities</span>
        </router-link>
        <router-link to="/about" class="navbar-item" @click="closeBurgerHandler">
        <span class="icon"><font-awesome-icon icon="fa-solid fa-info-circle" /></span>
          <span>About</span>
        </router-link>
        <router-link to="/membership" class="navbar-item" @click="closeBurgerHandler">
          <span class="icon"><font-awesome-icon icon="fa-solid fa-person-rifle" /></span>
          <span>Membership</span>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
    name: 'NavComponent',
    methods: {
        burgerHandler(event) {
        const menuEle = document.querySelector(`#${event.target.dataset.target}`);
        event.target.classList.toggle('is-active');
        menuEle.classList.toggle('is-active');
        },
        closeBurgerHandler() {
        const burgerEle = document.querySelector('.navbar-burger');
        const menuEle = document.querySelector(`#${burgerEle.dataset.target}`);
        burgerEle.classList.remove('is-active');
        menuEle.classList.remove('is-active');
        }
    }
}
</script>

<style>
@media (min-width: 1024px) {
  nav {
    /* Intent is to provide a tree background for only the first box */
    background-image: url('@/assets/forest.svg');
    background-size: 195px;
    background-repeat: repeat-y;
    background-position: right top;
  }
}
</style>